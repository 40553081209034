I18n.translations.fr = {
  service_option: {
    name: "Nom",
    max_quantity: "Quantité maximale",
    delivery_time: "Délai supplémentaire",
    price: "Prix de l'option",
    euro_excluding_tax: "€ HT",
    days: "jour(s)",
    commission_begin: "Soit",
    commission_end: "€ de commission pour vous."
  },
  paypal: {
    locale: 'fr_FR'
  }
};

I18n.translations.en = {
  service_option: {
    name: "Name",
    max_quantity: "Max quantity",
    delivery_time: "Delivery time",
    price: "Price",
    euro_excluding_tax: "€ tax free",
    days: "day(s)",
    commission_begin: "€",
    commission_end: "commission for you."
  },
  paypal: {
    locale: 'en_US'
  }
};

I18n.translations.de = {
  service_option: {
    name: "Name",
    max_quantity: "Maximale Menge",
    delivery_time: "Zusätzliche Zeit",
    price: "Optionspreis",
    euro_excluding_tax: "€ ohne MwS",
    days: "Tag(en)",
    commission_begin: "",
    commission_end: "€ Provision für Sie"
  },
  paypal: {
    locale: 'de_DE'
  }
};

I18n.translations.nl = {
  service_option: {
    name: "Naam",
    max_quantity: "Maximale hoeveelheid",
    delivery_time: "Extra tijd",
    price: "Optieprijs",
    euro_excluding_tax: "€ - excl. belastingen",
    days: "dag(en)",
    commission_begin: "€",
    commission_end: "commissie voor jou."
  },
  paypal: {
    locale: 'nl_NL'
  }
};

I18n.translations.es = {
  service_option: {
    name: "Nombre",
    max_quantity: "Cantidad máxima",
    delivery_time: "Tiempo adicional",
    price: "Precio de la opción",
    euro_excluding_tax: "€ sin IVA",
    days: "día(s)",
    commission_begin: "Comisión de",
    commission_end: "€ para ti."
  },
  paypal: {
    locale: 'es_ES'
  }
};

I18n.translations.pt = {
  service_option: {
    name: "Nome",
    max_quantity: "Quantidade máxima",
    delivery_time: "Hora adicional",
    price: "Preço da opção",
    euro_excluding_tax: "€ IVA não inc",
    days: "dia(s)",
    commission_begin: "",
    commission_end: "€ de comissão para você"
  },
  paypal: {
    locale: 'pt_PT'
  }
};

I18n.translations.it = {
  service_option: {
    name: "Nome",
    max_quantity: "Quantità massima",
    delivery_time: "Tempo aggiuntivo",
    price: "Prezzo opzione",
    euro_excluding_tax: "€ Tasse escluse",
    days: "giorno(i)",
    commission_begin: "",
    commission_end: "€ di commissione per te."
  },
  paypal: {
    locale: 'it_IT'
  }
};